import React, { createContext, useEffect, useState, useRef, useContext } from 'react';
import supabase from '../lib/supabase';

import { AccountContext } from './AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';

// Create contexts
const ScreenConfigContext = createContext();

const fetchUUID = () => {
  return localStorage.getItem('screen_uuid');
}

function shortIdFromUUID(uuid) {
	const shortId = uuid.split('-')[0]; // First 8 characters
	const checksum = parseInt(uuid.split('-')[1], 16) % 10; // Add a 0-9 checksum
	return `${shortId}${checksum}`;
}

const ScreenConfigProvider = ({ children }) => {
  const [screenConfig, setScreenConfig] = useState(null);
  const screenSubscriptionRef = useRef(null);
  const { account, user } = useContext(AccountContext);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchScreenConfig = async () => {
    const fingerprint = await fetchUUID(user.id);
    if (!fingerprint) return;

    //console.log("Fingerprint",fingerprint);

    const { data, error } = await supabase
    .from('screen_config')
    .select('*')
    .eq('fingerprint', fingerprint);

    if (error) {
      console.error('Error fetching tablet config:', error);
    } else {
      if (data?.[0]?.display_name === null) data[0].display_name = shortIdFromUUID(data[0].id);
      //console.log("Screen Config",data?.[0]);
      setScreenConfig(data?.[0]);
    }
  };

  const subscribeToScreenConfig = async () => {
    if (screenSubscriptionRef.current) return; // Prevent multiple subscriptions

    const fingerprint = await fetchUUID(user.id);
    if (!fingerprint) return;

    //console.log(fingerprint,user.id);
    const screenSubscription = supabase
      .channel('screen-changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'screen_config', filter: `fingerprint=eq.${fingerprint}` },
        (payload) => {
			    //console.log("Payload",payload);
          if (payload.eventType === 'UPDATE') {
			      //console.log("Update");
			      if (!payload?.new?.display_name) payload.new.display_name = shortIdFromUUID(payload?.new?.id);

            //console.log("Setting Screen Config to",payload.new);
            setScreenConfig(payload.new);
          } else if (payload.eventType === 'DELETE') {
			      //console.log("Delete");
            setScreenConfig(null);
            account.logout();
          }
        }
      )
      .subscribe((status) => {
        //console.log("screen_config status",status)
        if (status === 'SUBSCRIBED') {
          screenSubscriptionRef.current = screenSubscription;
        } else if (status === 'SUBSCRIPTION_ERROR' || status === 'CHANNEL_ERROR') {
          if (screenSubscriptionRef.current) {
            supabase.removeChannel(screenSubscriptionRef.current);
            screenSubscriptionRef.current = null;
          }
          setTimeout(() => {
            subscribeToScreenConfig();
          }, 1000); // Retry subscription after a delay
        } else {
          screenSubscriptionRef.current = null;
          setTimeout(() => {
            subscribeToScreenConfig();
          }, 1000); // Retry subscription after a delay
        }
      });
  };

  useEffect(() => {
    const setupSubscriptions = async () => {
      fetchScreenConfig();
      subscribeToScreenConfig();
    };

    if (user) {
      setupSubscriptions();
    }

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        if (!screenSubscriptionRef.current) {
          //console.log("The page is now visible, re-setting the subscription")
          setupSubscriptions();
        }
      } else {
        //console.log("The page is now hidden from the user.");
        if (screenSubscriptionRef.current) {
          supabase.removeChannel(screenSubscriptionRef.current);
          screenSubscriptionRef.current = null; // Reset the ref
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
        //console.log("Cleanup on unmount or dependency change");
        if (screenSubscriptionRef.current) {
          supabase.removeChannel(screenSubscriptionRef.current); // Remove the relevant channel
          screenSubscriptionRef.current = null; // Reset the ref
        }
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [user]);

  useEffect(() => {
    if (screenConfig?.screen_type) {
      const targetPath = '/' + screenConfig.screen_type;
      //console.log(targetPath,location.pathname);
      if (location.pathname !== targetPath) {
        //console.log("Navigating to the screen:", screenConfig.screen_type);
        navigate(targetPath);
      }
    }
  }, [screenConfig?.screen_type, location.pathname, navigate]);

  return (
    <ScreenConfigContext.Provider value={{ screenConfig }}>
        {children}
    </ScreenConfigContext.Provider>
  );
};

export { ScreenConfigContext, ScreenConfigProvider };