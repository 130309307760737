import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../context/AccountContext';

function TodaysEvents({ calendarId, maxEvents = 10 }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useContext(AccountContext);

  // Edge function endpoint URL
  const EDGE_FUNCTION_URL = 'https://yzxiqswbpvwvdzgwktpt.supabase.co/functions/v1/google-get-calendar-events';

  const fetchEvents = async () => {
    //console.log("calendarId",calendarId);
    if (!calendarId) return; // Only fetch if calendarId is provided
    try {
      const token = await getAccessToken();
      const url = `${EDGE_FUNCTION_URL}?calendarId=${encodeURIComponent(calendarId)}&maxResults=${maxEvents}`;
      //console.log("Fetching calendar");
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      const data = await response.json();
      const processedEvents = data.items.map((event) => ({
        ...event,
        start: new Date(event.start.dateTime || event.start.date),
        end: new Date(event.end.dateTime || event.end.date)
      }));
      //console.log(processedEvents);
      setEvents(processedEvents);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching events from edge function:', err);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!calendarId) return;
    // Fetch events once on mount (or when calendarId changes)
    fetchEvents();
    // Set up a 30-minute refresh interval
    const intervalId = setInterval(fetchEvents, 1800000);
    return () => clearInterval(intervalId);
  }, [calendarId, maxEvents]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching events: {error.message}</p>;

  return (
    <div className="calendar_list">
      {events.length === 0 ? (
        <p>No events today</p>
      ) : (
        <ul className="calendar_event">
          {events.map((event) => (
            <li key={event.id}>
              <span className="fa-li">
                <i className="fa-regular fa-calendar-check"></i>
              </span>
              {event.start.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }).replace(/^0/, '')}
              &nbsp;to&nbsp;
              {event.end.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }).replace(/^0/, '')}
              &nbsp;-&nbsp;{event.summary}
              <div className="description">{event.description || ''}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TodaysEvents;
