import React, { useContext, useEffect } from 'react';
import supabase from '../../lib/supabase';
import { ScreenConfigContext } from '../../context/ScreenConfigContext';

const DeviceNameDisplay = () => {
  const { screenConfig } = useContext(ScreenConfigContext);

  useEffect(() => {
    // Only start the timer if we have a valid config and show_name is true
    if (!screenConfig?.id || !screenConfig?.show_name) return;

    const timer = setTimeout(() => {
      // Update the show_name field to false in the database after 30 seconds
      supabase
        .from('screen_config')
        .update({ show_name: false })
        .eq('id', screenConfig.id)
        .then(({ error }) => {
          if (error) {
            console.error('Failed to update show_name to false:', error);
          }
        });
    }, 30000);

    return () => clearTimeout(timer);
  }, [screenConfig?.id, screenConfig?.show_name]);

  // Only render the box if show_name is true
  //console.log(screenConfig);
  if (!screenConfig?.show_name) return null;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        backgroundColor: '#ababab',
        color: 'white',
        padding: '10px',
        borderRadius: '4px',
        zIndex: 9999,
      }}
    >
      {screenConfig.display_name ?? 'Device Name: ' + screenConfig.display_name}
    </div>
  );
};

export default DeviceNameDisplay;