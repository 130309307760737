import React, { useContext, useState, useEffect, useRef, Fragment } from 'react';
import Masonry from 'react-masonry-css';
import { Card, CardBody, Progress } from 'reactstrap';
import './notification.css';
import '../../assets/css/progress.css';

import supabase from '../../lib/supabase';
import TodaysEvents from '../../components/TodaysEvents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import Clock from '../../components/Clock';
import { LocationContext } from '../../context/LocationContext.js';
import { OrdersContext } from '../../context/OrdersContext';
import { ScreenConfigContext } from '../../context/ScreenConfigContext.js';
import { AccountContext } from '../../context/AccountContext.js';

var maxEvents = 5;

const NotificationScreen = () => {
  const { activeLocation } = useContext(LocationContext);
  const { totalOrderCount, totalOrderLineItems } = useContext(OrdersContext);
  const { screenConfig } = useContext(ScreenConfigContext);
  const { account } = useContext(AccountContext);
  const [checklists, setChecklists] = useState([]);
  const [runningLow, setRunningLow] = useState([]);
  const previousOrderCount = useRef();

  useEffect(() => {
    if (previousOrderCount.current == null) {
      previousOrderCount.current = totalOrderCount;
    }
    if (totalOrderCount < previousOrderCount.current) {
      getRunningLow();
    }
    previousOrderCount.current = totalOrderCount;
  }, [totalOrderCount]);

  useEffect(() => {
    if (!activeLocation) return;
    if (activeLocation.subscription_level < 3) return;

    fetchChecklistStatus();
    getRunningLow();

    const intervalId = setInterval(() => {
      fetchChecklistStatus();
    }, 300000); // refresh every 5 minutes
  
    return () => clearInterval(intervalId);
  }, [activeLocation]);

  const getRunningLow = async () => {
    const { data, error } = await supabase
      .from('low_prep_items')
      .select('*')
      .eq('location_id', activeLocation.id);

    if (error) {
      throw error;
    }
    const sortedData = data.sort((a, b) => {
      const ratioA = a.actual / a.par;
      const ratioB = b.actual / b.par;
      return ratioA - ratioB;
    });
    setRunningLow(sortedData);
  };

  const fetchChecklistStatus = async () => {
    try {
      const today = new Date();
      const { data, error } = await supabase
        .from('checklists')
        .select(`
            *,
            checklist_items (
              id,
              checklist_initials (initials, completion_date)
            )
          `)
        .eq('location_id', activeLocation.id)
        .eq('checklist_items.checklist_initials.completion_date', Intl.DateTimeFormat('en-US').format(today));

      if (error) throw error;
  
      const currentHour = today.getHours();
      const currentDay = today.getDay();
  
      const processedChecklists = data
        .map((checklist) => {
          let display = (checklist.start_hour && checklist.start_hour * 1 < currentHour + 1) ||
                        (checklist.day_of_week && checklist.day_of_week === currentDay);
          let completedToday = 0;
          const totalItems = checklist.checklist_items.length;
          checklist.checklist_items.forEach((item) => {
            if (Array.isArray(item?.checklist_initials) && item.checklist_initials.length > 0)
              completedToday++;
          });
          const completionPercent = totalItems ? (completedToday / totalItems) * 100 : 0;
          if (display && completedToday < totalItems) {
            return {
              id: checklist.id,
              name: checklist.name,
              completedToday,
              totalItems,
              completionPercent,
            };
          } else {
            return null;
          }
        })
        .filter((checklist) => checklist !== null);
  
      setChecklists(processedChecklists);
    } catch (error) {
      console.error('Error fetching checklist data:', error);
    }
  };

  if (!activeLocation) return (<div>Loading...</div>);

  // Define breakpoints for the masonry grid
  const breakpointColumnsObj = {
    default: 2,
    768: 2,
    480: 1,
  };

  return (
    <Fragment>
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          <div className="logo-src" />
        </div>
        <div className="app-header-right">
          <Clock />
        </div>
      </div>
      <div className="dashboard-container">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >

          <Card className="half-card card" key="orders">
            <CardBody className="card_body">
              <div className="queue" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                <div className="right-border" style={{ textAlign: 'center', width: '50%' }}>
                  <h3>Orders in Queue</h3>
                  <div className="count">{totalOrderCount}</div>
                </div>
                <div style={{ textAlign: 'center', width: '50%' }}>
                  <h3>Items in Queue</h3>
                  <div className="count">{totalOrderLineItems}</div>
                </div>
              </div>
            </CardBody>
          </Card>

          {account?.google_connected && (
            <Card className="half-card card" key="calendar">
              <CardBody className="card_body">
                <h3>Calendar</h3>
                <div id="calendar">
                  <TodaysEvents calendarId={screenConfig?.google_calendar_id} maxEvents={maxEvents} />
                </div>
              </CardBody>
            </Card>
          )}

          <Card className="half-card card" key="checklists">
            <CardBody className="card_body">
              <h3>Checklist Progress</h3>
              <div id="checklist_progress">
                {checklists.length > 0 ? (
                  <div className="checklists">
                    {checklists.map((checklist) => (
                      <div key={checklist.name}>
                        <div className="title">{checklist.name}</div>
                        <div className="fraction">{checklist.completedToday}/{checklist.totalItems}</div>
                        <div className="checklist-row">
                          <div className="progress-section">
                            <Progress 
                              className={`progress-bar-rounded ${
                                checklist.completionPercent < 25
                                  ? 'bar-red'
                                  : checklist.completionPercent < 75
                                    ? 'bar-yellow'
                                    : 'bar-blue'
                              }`} 
                              style={{width:'95%'}} 
                              value={checklist.completionPercent}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>All Checklists Are Complete!</div>
                )}
              </div>
            </CardBody>
          </Card>

          <Card className="half-card card" key="running-low">
            <CardBody className="card_body">
              <h3>Running Low</h3>
              <div id="running-low">
                {runningLow.map((item) => {
                  if ((item.actual / item.par) > 0.5) return null;
                  const fontClass = (item.actual / item.par) < 0.25
                    ? 'text-red'
                    : (item.actual / item.par) < 0.5
                      ? 'text-yellow'
                      : 'text-blue';
                  return (
                    <div key={item.name} className={`running-low-item progress-bar-rounded ${fontClass}`}>
                      <FontAwesomeIcon icon={faCircleExclamation} />&nbsp;
                      {item.name} {`(${(item.actual * 1).toFixed(1)} remaining - count to verify)`}
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </Masonry>
      </div>
    </Fragment>
  );
};

export default NotificationScreen;
